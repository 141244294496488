import config from '../../../config/environment/envConfig';

export default {
  SITE_URL: config.siteUrl,
  MCWS_URL: config.mcwsUrl,
  EHMP_URL: config.ehmpUrl,
  EHM_URL: config.ehmUrl,
  SUBDOMAIN: config.subdomain,
  LAUNCH_SCRIPT_URL: config.launchScriptUrl,
  HOT_JAR_ID: config.hotJarId,
  CC_GQL_URL: config.ccGqlUrl,
};
