import { getBrowserStorage } from './common';

export function createPageTracking() {
  let EhiSessionContext: any = {};
  EhiSessionContext = {
    sysEnv: {
      siteGroup: 'EHI'
    },
    page: {
      section: 'homepage',
      name: 'home'
    },
    static: {},
    form: {},
    leadInfo: {},
    marketing: {},
    profile: {},
    quote: {},
    search: {},
    shop: {},
    sort: {},
    user: {},
    video: {}
  };

  if (getBrowserStorage('allid')) {
    EhiSessionContext.static.allianceId = getBrowserStorage('allid');
  }
  if (getBrowserStorage('leadId')) {
    EhiSessionContext.leadInfo.leadId = getBrowserStorage('leadId');
  }

  if (window._waEvents) {
    window._waEvents.push('pageView', EhiSessionContext);
  }
}
