import * as React from 'react';
import envConstants from '../../../globals/constants/environment-constants';

const NotSupported = () => {
  const [pathPrefix, setPathPrefix] = React.useState('');
  React.useEffect(() => {
    setPathPrefix(envConstants.SITE_URL.includes('localhost') ? '' : 'mu-ehi/');
  }, []);

  const chromeImage = `/${pathPrefix || ''}imgs/chrome_48x48.gif`;
  const edgeImage = `/${pathPrefix || ''}imgs/edge_48x48.gif`;
  const fireFoxImage = `/${pathPrefix || ''}imgs/ff_48x48.gif`;
  const logoImage = `/${pathPrefix || ''}imgs/logo-white-eh-104x24.gif`;
  const css = `
    body {
      background: #fff;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    }
    .content {
      background: #fff !important;
      font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    }
    a {
      text-decoration: none;
      color: #3a5eba;
    }
    .header {
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #e5e5e5;
    }
    .header-content {
      max-width: 1200px;
      margin: 0px auto;
    }
    .header-content img {
      margin-top: 10px;
      padding-left: 25px;
    }
    .row-1,
    .row-2 {
      max-width: 1000px;
      height: 350px;
      margin: 50px auto;
    }
    .r1-left {
      display: inline-block;
      width: 50%;
      max-width: 450px;
      vertical-align: top;
    }
    .r1-left h1 {
      font-size: 54px;
    }
    .r1-left p {
      font-size: 40px;
    }
    .r1-right {
      display: inline-block;
      width: 50%;
      min-height: 325px;
      margin: 0px auto;
      background-repeat: no-repeat;
    }
    .r2-left {
      display: inline-block;
      width: 50%;
      max-width: 450px;
      vertical-align: top;
    }
    .r2-left p {
      font-size: 20px;
    }
    .r2-right {
      display: inline-block;
      width: 50%;
      min-height: 300px;
      margin: 20px 0 0 10px;
      background-color: #e9f1f4;
    }
    .r2r-1 {
      width: 100%;
      padding-left: 25px;
      font-size: 24px;
      font-weight: 400;
    }
    .r2r-row {
      width: 100%;
      padding: 0 0 20px 0;
    }
    .r2r-img {
      display: inline-block;
      width: 50px;
      margin: 0px 15px 0 15px;
      vertical-align: middle;
    }
    .r2r-a {
      width: 40%;
      display: inline-block;
      margin: 10px 0;
      font-size: 20px;
      font-weight: 600;
    }
    .r2r-b {
      text-align: right;
      font-size: 16px;
      font-weight: 600;
      width: 40%;
      display: inline-block;
    }
    @media screen and (min-width: 949px) {
      .r1-right {
        background-image: url('/${pathPrefix || ''}imgs/hero-486x325.jpg');
      }
    }
    @media screen and (max-width: 948.1px) {
      .row-1,
      .row-2 {
        width: 740px;
        height: auto;
        margin: 10px auto;
      }
      .r1-left {
        width: 325px;
        margin: 20px auto;
        padding-left: 10px;
      }
      .r1-right {
        width: 350px;
        max-width: 500px;
        min-height: 300px;
        margin: 20px auto;
        background-image: url('/${pathPrefix || ''}imgs/hero-348x232.jpg');
      }
      .r1-left h1 {
        font-size: 40px;
      }
      .r1-left p {
        font-size: 24px;
      }
      .r2-left {
        display: block;
        max-width: 534px;
        margin: 20px auto;
        width: 100%;
      }
      .r2-left p {
        font-size: 14px;
      }
      .r2-right {
        display: block;
        width: 100%;
        max-width: 534px;
        min-height: 300px;
        margin: 20px auto;
        padding-top: 1px;
      }
      .r2r-1 {
        width: 90%;
        font-size: 24px;
        font-weight: 400;
      }
      .r2r-a {
        width: 40%;
        display: inline-block;
        margin: 10px 0;
        font-size: 20px;
        font-weight: 600;
      }
      .r2r-img {
        margin: 0px 15px 0 15px;
        vertical-align: middle;
      }
      .r2r-b {
        text-align: right;
        font-size: 16px;
        font-weight: 600;
        width: 40%;
        display: inline-block;
        padding-right: 5px;
      }
    }
    @media screen and (max-width: 768.1px) {
      .row-1,
      .row-2 {
        width: 100%;
        height: auto;
      }
      .r1-left {
        display: block;
        width: 100%;
        margin: 20px auto;
        padding-left: 0px;
      }
      .r1-right {
        display: block;
        width: 100%;
        width: 100%;
        min-height: 220px;
        margin: 20px auto;
        background-image: url('/${pathPrefix || ''}imgs/hero-348x232.jpg');

      }
      .r1-left h1 {
        font-size: 32px;
      }
      .r1-left p {
        font-size: 24px;
      }
    }
    @media screen and (max-width: 500px) {
      .header-content img {
        padding-left: 2px;
      }
      .r2r-row {
        margin-bottom: 15px;
      }
      .r2r-a {
        display: block;
        width: auto;
        font-size: 20px;
        font-weight: 600;
        margin: 0;
      }
      .r2r-b {
        display: block;
        width: auto;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        padding: 0 0 0 0;
      }
      .r2r-img {
        margin: 0px 15px 0 15px;
        vertical-align: bottom;
        float: left;
      }
    }
    @media screen and (max-width: 425px) {
      .r1-right {
        background-image: url('/${pathPrefix || ''}imgs/hero-288x192.jpg');
        min-height: 190px;
      }
      .header-content img {
        padding-left: 0;
      }
    }`;

  return (
    <>
      <style>{css}</style>
      <div style={{ background: '#fff', fontFamily: 'Open Sans' }}>
        <div className="header">
          <div className="header-content">
            <img src={logoImage} alt="eHealth Insurance" width="104" height="24" />
          </div>
        </div>
        <div className="row-1">
          <div className="r1-left">
            <h1>Uh Oh. Your Browser Looks Outdated.</h1>
            <p>A change is in order.</p>
          </div>
          <div className="r1-right" />
        </div>
        <div className="row-2">
          <div className="r2-left">
            <p>
              It’s not your fault, or your browser’s. But time waits for no one and we require a
              newer version. You can check for updates on your computer or try a different browser
              from the ones listed here.
            </p>
          </div>
          <div className="r2-right">
            <div className="r2r-1">
              <p>Recommended Browsers</p>
            </div>
            <div className="r2r-row">
              <div className="r2r-img">
                <img src={chromeImage} width="48px" height="48px" alt="chrome" />
              </div>
              <div className="r2r-a">Google Chrome</div>
              <div className="r2r-b">
                <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
                  Download Latest
                </a>
              </div>
            </div>
            <div className="r2r-row">
              <div className="r2r-img">
                <img src={edgeImage} width="48px" height="48px" alt="edge" />
              </div>
              <div className="r2r-a">Microsoft Edge</div>
              <div className="r2r-b">
                <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
                  Download Latest
                </a>
              </div>
            </div>
            <div className="r2r-row">
              <div className="r2r-img">
                <img src={fireFoxImage} width="48px" height="48px" alt="firefox" />
              </div>
              <div className="r2r-a">Mozilla Firefox</div>
              <div className="r2r-b">
                <a href="https://www.mozilla.org/en-US/firefox/" target="_blank" rel="noreferrer">
                  Download Latest
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotSupported;
